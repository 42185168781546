.GridcellsPane {
  z-index: 580;
}

.ant-tour {
  z-index: 50000 !important;
}

#listItems:hover {
  color: #224669 !important;
  font-weight: bold;
}

#listItems:active {
  color: #1668dc;
}

#listItems {
  display: flex;
  cursor: pointer;
}

.ant-collapse-content-box {
  padding: 0 !important;
}

.first-column,
.second-column {
  width: 45%;
}

.third-column {
  width: 10%;
}

.download-img {
  z-index: 1;
  margin-top: 15px;
  margin-right: 12px;
  cursor: pointer;
}

.right-map {
  float: right;
}

.terms-conditions-body {
  text-align: justify;
}

.sidebar-location,
.sidebar-module {
  font-family: "National";
  padding: 9px 15px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #2a547c;
  margin-bottom: 0;
}

.sidebar-location .info-style {
  margin-left: 10px;
}

.indices-info-sidebar-location {
  font-family: "National";
  background: #f8f8f8;
  padding: 9px 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #2a547c;
  margin-bottom: 0;
}

.indices-info-sidebar-location .info-style {
  margin-left: 0px;
}

.indices-info-sidebar-location {
  font-family: "National";
  background: #f8f8f8;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #2a547c;
  margin-bottom: 0;
}

.info-icon {
  padding-top: 0px;
  padding-bottom: 0px;
  top: 23%;
}

.info-icon-dss {
  padding-top: 0px;
  padding-bottom: 0px;
  top: 0%;
  margin-right: 9%;
}

.info-icon-on-check {
  padding-top: 0px;
  padding-bottom: 0px;
  top: 14px;
}

.sidebar-indicator {
  background: #f8f8f8;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  padding: 9px 15px;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #2a547c;
  margin-bottom: 0;
}

.sidebar-select {
  background: #e8e7e7;
  border-radius: 0;
  border: 0;
  padding: 9px 15px;
  font-weight: 600;
  font-size: 14px;
  color: #2a547c;
  width: 100%;
  text-align: left;
}

.sidebar-select::after {
  float: right;
}

.drought-submenu > p {
  background: #e8e7e7;
  border-radius: 0;
  border: 0;
  font-weight: 600;
  width: 100%;
  padding: 3px 10px;
  font-size: 14px;
  color: #2a547c;
  text-align: left;
  margin-bottom: 0;
}

.drought-submenu > p::after {
  float: right;
}

.drought-submenu {
  display: block;
}

.drought-submenu:hover {
  background-color: white;
  color: #2a547c;
}

.caret-dropdown {
  float: right;
  margin-top: 5px;
}

.drought-sidebar-dropdown > div {
  display: block;
}

.drought-sidebar-dropdown > div > .Dropdown_menu__3k4sX {
  display: block;
  position: relative;
  top: unset;
  right: unset;
  box-shadow: none;
  border-radius: 0;
}

.drought-sidebar-dropdown > div > .Dropdown_menu__3k4sX > ul {
  margin-top: 0;
}

.drought-submenu > div {
  display: block;
  position: relative;
  top: unset;
  right: unset;
  box-shadow: none;
  border-radius: 0;
}

.drought-li-checked {
  width: 85%;
  list-style-type: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  padding: 0;
  margin-top: 10px;
  cursor: pointer;
  color: #ffffff;
  background: #2a547c;
  padding: 5px;
  margin-right: 10px;
}

.drought-submenu-li {
  width: 90%;
  word-wrap: break-word;
  padding: 5px;
  list-style-type: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #363636;
  padding: 0;
  margin-top: 10px;
  cursor: pointer;
}

.frequency {
  margin-left: -10px;
}

.drought-filter-section {
  height: 79.4vh !important;
}

.blue-compare {
  color: #2a547c;
}

.panel {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ResizePanel-module_ResizeContentVertical__1OGHY {
  max-height: 79.4vh !important;
}

.header {
  background: lightblue;
  min-height: 50px;
  height: 100%;
}

.footer {
  /* background: lightblue; */
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  text-align: center;
  justify-content: flex-start;
  overflow: hidden;
}

.footerArea {
  flex-grow: 1;
  display: flex;
  min-height: 200px;
  flex-flow: column;
  overflow-y: auto;
}

.drought-footer-area {
  flex-grow: 1;
  display: flex;
  min-height: 200px;
  flex-flow: column;
  overflow: auto;
  overflow-y: hidden !important;
}

.drought-mod-header {
  background: #28537d;
}

.drought-mod-header > .modal-title {
  font-weight: normal;
  font-size: 24px !important;
  line-height: 18px;
  color: #ffffff;
}

.drought-mod-header > .close > span {
}

.drought-mod-header > .close {
  background: #28537d;
  border: 0;
}

.drought-modal-body {
  padding-bottom: 0px !important;
}

.drought-mod-2 {
  background: #ffffff;
  border: 1px solid #e8e7e7;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 5px 15px 39px 15px;
  margin-top: 4px;
}

.drought-mod-2 > p {
  text-align: justify;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  margin-bottom: 0;
  height: 149px;
}

.drought-modal-section {
  /* margin-top: 14vh; */
  max-width: 575px;
  display: flex;
  height: 113vh;
  align-items: center;
}

.drought-modal-section .modal-content {
  border: 0;
}

.landing-page-card {
  /* overflow: hidden; */
}

.drought-mod-1 > img {
  width: 100%;
  height: 39vh;
  object-fit: cover;
}

.drought-mod {
  padding-right: 19px;
}

.drought-img-section {
  background: #f7f7f7;
  border: 1px solid #e8e7e7;
  box-sizing: border-box;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.drought-img {
  margin-top: 1.5vh;
  margin-bottom: 1.5vh;
  /* margin-left: 1vh; */
  margin-right: 30%;
  padding-right: 0.5vh;
  height: 51px;
  margin-left: 35%;
}

.drought-mod-pl {
  padding-left: 0;
}

.drought-img-text {
  background: #ffffff;
  border: 1px solid #e8e7e7;
  box-sizing: border-box;
  border-radius: 2px;
  text-align: center;
  padding: 7px 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #2a547c;
}

.drought-mod-text {
  color: #28537d;
  font-weight: 600;
  display: block;
  cursor: pointer;
}

.weather-menu-sub {
  display: block !important;
  width: 100%;
  border-bottom: 1px solid #e8e7e7;
  padding-top: 10px;
  padding-bottom: 10px;
}

.weather-menu-sub:last-child {
  border-bottom: 0;
}

.weather-menusub-ul > ul {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

.weather-menu-sub:hover {
  color: #dc9023;
  /* width:120px; */
}

.category-menuitem-img > i {
  font-weight: 700;
}

.dashboard-border {
  cursor: pointer;
  /* height: 68vh; */
  background: #ffffff;
  border: 1px solid #c6c6c6;
  box-sizing: border-box;
  box-shadow: 0px 5px 24px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
}

.dashboard-text {
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  color: #2a547c;
  display: inline-block;
}

.mrl-1 {
  margin-left: 2.5vw;
}

.dashboard-header {
  margin: 20px;
}

.dashboard-card {
  background: #ffffff;
  border: 0.5px solid #a6a6a6;
  box-sizing: border-box;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 28px;
  margin-bottom: 40px;
  height: 200px;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: row;
}

.text {
  padding: 10px;
}

.dt-1 {
  display: inline-block;
  margin-left: 25px;
}

.contingency-map-container .leaflet-control-layers-expanded {
  margin-top: 40px;
}

.leaflet-control-layers-expanded {
  padding: 6px 8px 6px 8px !important;
}

/* transparency tool style */
.leaflet-control-layers-overlays label div span {
  display: none !important;
}

.leaflet-control-layers-overlays label div:nth-child(2) {
  display: inline-block;
  width: 15px;
  height: 150px;
  padding: 0;
}

.leaflet-control-layers-overlays label div:nth-child(2) input[type="range" i] {
  appearance: auto !important;
}

.leaflet-control-layers-range {
  /* -webkit-appearance: slider-vertical !important; */
  width: 150px !important;
  height: 15px !important;
  margin: 0 !important;
  transform-origin: 75px 75px !important;
  transform: rotate(-90deg) !important;
}

.slider-wrapper {
  display: inline-block;
  width: 20px;
  height: 150px;
  padding: 0;
}

.slider-wrapper input {
  width: 150px;
  height: 20px;
  margin: 0;
  transform-origin: 75px 75px;
  transform: rotate(-90deg);
}

/* drought chart loader styles */
.drought-loader-container {
  /* align-items: center;
    align-content: center;
    justify-content: center; */
  display: block;
  height: 100%;
  width: 100%;
  z-index: 1000;
  background-color: #f8f8f8ad !important;
  text-align: center;
}

.drought-loader {
  top: 20% !important;
  left: 40% !important;
  z-index: 10000 !important;
  position: absolute !important;
}

.drought-loader .spinner-border {
  color: #dc9023 !important;
  border-width: 5px !important;
  width: 40px !important;
  height: 40px !important;
}

.drought-error-style {
  display: flex;
  width: 600px;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin-left: auto !important;
  margin-right: auto !important;
}

.crop-top {
  position: fixed;
  top: 12.6vh;
  width: 100%;
}

.header-back-button {
  cursor: pointer;
  margin-top: 9%;
  float: right;
}

.public-api-header-back-button {
  cursor: pointer;
}

.manage-crops-header-back-button {
  cursor: pointer;
}

.manage-static-header-back-button {
  cursor: pointer;
  float: right;
}

.user-search-details-no-records {
  margin-top: 1%;
  margin-left: 37%;
}

.news-feed-header-back-button {
  cursor: pointer;
  float: right;
}

.manage-header-back-button {
  cursor: pointer;
  float: left;
}

.create-user-guide-title {
  margin-left: 0.6rem;
}

.manage-user-guide-page-title {
  display: flex;
  margin-top: 9px;
  margin-right: 1.2rem;
  /* margin-left: 37%; */
  align-items: center !important;
  text-align: center !important;
  justify-content: center !important;
  font-family: National;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
}

.crop-header {
  /* box-shadow: 0 2px 4px rgb(0 0 0 / 16%); */
  width: 100%;
  display: block;
  padding-top: 23px;
  padding-bottom: 17px;
  padding-left: 28px;
  padding-right: 10px;
  height: 12.6vh;
}

.crops-heading {
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  color: #2a547c;
  display: inline-block;
  margin-left: 24px;
}

.crop-button {
  background: #2a547c;
  border-radius: 4px;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  padding: 10px 20px;
  margin-right: 68px;
  float: right;
  border: 0;
}

.crop-button:disabled {
  background: #2a547c;
  border-radius: 4px;
  float: right;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  /* margin: 24px 68px;
  padding: 10px 20px; */
  border: 0;
  opacity: 0.7;
}

.sidebar-crop-location {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #dc9023;
  margin-left: 16px;
}

.crop-table-list tr:last-child td {
  border-bottom: 1px solid #e8e7e7 !important;
}

.crop-sidebar-section {
  width: 18%;
  padding-top: 16px;
  display: inline-block;
  border: 0.5px solid #eeebeb;
  height: 79.4vh;
}

.crop-table-list {
  display: inline-block;
  width: 82% !important;
  margin-top: 0 !important;
}

.crop-table-list tbody {
  height: 400px !important;
}

.crop-tr-items {
  border-top: 1px solid #e8e7e7;
  border-bottom: 1px solid #e8e7e7;
  margin-top: 16px;
}

.crop-td-items {
  border: 0;
  padding: 14px 5% !important;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #363636;
}

.no_records_label {
  text-align: center;
  font-size: 20px;
  margin-top: 10px;
}

.manage-crop-body {
  height: "460px" !important;
}

.manage-crop-row {
  margin-top: 10px !important;
}

.crop-edit-button {
  background: #ffffff;
  border: 0.2px solid #a1a1a1;
  border-radius: 4px;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #2a547c;
  padding: 0px 14px;
  margin-right: 20px;
  margin-top: 5px;
}

.crop-delete-button {
  padding: 0px 14px;
  background: #ffffff;
  border: 0.2px solid #a1a1a1;
  border-radius: 4px;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #eb5757;
}

.add-crop-modal {
  display: unset;
}

.addcrop-modal-header > button {
  border: 0;
  background-color: white;
}

.addcrop-modal-header > button > span {
  font-size: 36px;
}

.addcrop-modal-header {
  border: 0;
  padding: 0 16px;
}

.addcrop-modal-header > h5 {
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #2a547c;
}

.crop-modal-sec {
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100vh;
}

.crop-cancle-button {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #2a547c;
  border: 1px solid #2a547c;
  box-sizing: border-box;
  border-radius: 4px;
  display: inline-block;
  background-color: white;
  padding: 11px 30px;
}

.crop-add-button {
  padding: 11px 30px;
  display: inline-block;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  background: #2a547c;
  border-radius: 4px;
  border: 0;
}

.crop-add-button:disabled {
  padding: 11px 30px;
  display: inline-block;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  background: #2a547c;
  border-radius: 4px;
  border: 0;
  width: unset;
}

.crop-modal-footer {
  border: 0;
}

.bulletin-header {
  margin-top: 20.9vh;
  background: #ffffff;
  border: 1px solid #e8e7e7;
}

.news-feed-header {
  margin-top: 20.9vh;
  background: #ffffff;
  border: 1px solid #e8e7e7;
}

.bullet-dp-menu {
  padding: 0;
  top: -44px !important;
}

.bullet-modal {
  display: flex;
  height: 90vh;
  align-items: center;
}

.bullet-modal .modal-content {
  border: 0;
  min-height: 240px;
  height: auto;
}

.bullet-dp-item {
  border-bottom: 1px solid #e8e7e7;
}

.bullet-modal-dp > button {
  width: 100%;
  text-align: justify;
}

.bullet-modal-dp-menu {
  width: 100%;
}

.bulletin-header-h3 {
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  color: #2a547c;
  margin: 24px 29px;
}

.home-page-bulletin-header-h3 {
  display: inline-block;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  color: #2a547c;
  margin: 24px -7px;
  display: inline-block;
}

.news-feed-header-h3 {
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  color: #2a547c;
  margin: 24px 70px;
  margin-left: -7%;
}

.bulletin-dp > button {
  background: #ffffff;
  border: 1px solid #e8e7e7;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  line-height: 16px;
  color: #363636;
}

.bulletin-dp > button:hover,
.bulletin-dp > button:focus,
.bulletin-dp > button:active {
  background-color: white;
  box-shadow: none;
  color: #363636;
  border: 1px solid #e8e7e7;
}

.bullet-card {
  background: #ffffff;
  border: 1px solid #e8e7e7;
  border-radius: 4px;
  min-height: 240px;
  max-height: auto;
}

.bullet-card:hover {
  box-shadow: 0 0 20px #333;
}

.bullet-card > img {
  width: 100%;
}

.temp-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #363636;
  margin-left: 14px;
}

.manage-news-feed-filter-btn {
  box-shadow: none !important;
}

.manage-news-feed-filter-btn:disabled {
  box-shadow: none !important;
  width: 9vw;
}

.bullet-temp-text {
  font-size: 12px;
  line-height: 16px;
  color: #363636;
  margin-left: 14px;
}

.temp-time {
  margin-left: 14px;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #363636;
}

.create-temp-Bullet {
  font-size: 14px !important;
  height: auto !important;
  padding: 10px;
  margin-left: 20px;
}

@media only screen and (max-width: 769px) {
  .crop-sidebar-section {
    width: 100%;
  }

  .crop-table-list {
    width: 100% !important;
  }

  .crop-button {
    margin: 0 !important;
  }
}

.row-mb {
  margin-bottom: 8px;
}

.label-lp {
  padding-left: 0 !important;
}

.checkbox-rp {
  padding-right: 8px !important;
  width: 15.3%;
}

.api-heading-mt {
  display: inline-block;
  margin-left: 1%;
}

.subheader-apikey {
  margin-left: 4%;
  margin-bottom: 1%;
}

.map-container .leaflet-right .leaflet-control,
.drought-map-container .leaflet-right .leaflet-control,
.contingency-map-container .leaflet-right .leaflet-control {
  margin-right: 20px;
  /* margin-bottom:5px !important; */
}

.drought-map-container .leaflet-top {
  top: 60px !important;
}

.contingency-map-container .leaflet-right .leaflet-control {
  margin-top: 20px;
}

.rainfall-graph-button:focus {
  box-shadow: none !important;
}

.alert-table-modal {
  max-width: 750px !important;
  display: flex;
  height: 100vh;
  align-items: center;
}

.alert-table-modal .modal-body {
  padding: 0 !important;
}

.alert-table-modal .modal-content {
  height: 330px !important;
}

.alert-table-design {
  width: 725px;
  border-spacing: 0;
  margin: 10px;
  padding: 1.2rem 1rem;
  border: 0.01rem solid black;
}

.alert-table-design > tbody > tr {
  border-top: none !important;
}

.alert-table-design tr:last-child td {
  border-bottom: 0;
}

.alert-table-design th {
  text-align: center;
}

.alert-table-design th,
.alert-table-design td {
  margin: 0;
  padding: 0.5rem;
  border-bottom: 0.01rem solid black;
  border-right: 0.01rem solid black;
}

.alert-table-design td:last-child {
  border-right: 0;
}

.alert-table-style tr td {
  border-color: #e8e7e7 !important;
  border-collapse: collapse;
  border-right-width: 1px;
  text-align: center !important;
  padding: 1.5rem 0.8rem;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #363636;
}

/* .alert-table-modal tr th {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18.7px;
    color: #363636;
  } */

.alert-table-modal .modal-footer {
  justify-content: right;
  border-top: 0;
  padding-top: 0px;
}

.rainfall-modal .modal-footer {
  justify-content: right;
  font-family: "National";
  font-weight: normal;
  color: #373d3f;
  border-top: 0px;
  padding-top: 0px;
}

.drought-phase-modal .modal-footer {
  border-top: 0px;
  font-family: "National";
  font-weight: normal;
  font-style: italic;
  font-size: 14px;
  line-height: 18.16px;
  color: #000;
}

.mandatory-design-img {
  height: 84%;
  margin-top: 20px;
  width: 59%;
  margin-left: 19%;
}

.rainfall-modal .modal-header,
.drought-phase-modal .modal-header {
  border-bottom: 0px;
  font-family: "National";
  font-weight: normal;
  color: #373d3f;
  padding: 10px !important;
}

.rainfall-modal {
  max-width: 620px;
}

.drought-phase-modal {
  max-width: 650px;
}

.rainfall-modal .modal-body .apexcharts-toolbar {
  top: 5px !important;
}

.rainfall-modal .modal-body {
  padding-top: 0px;
}

.drought-check-modal {
  display: flex;
  align-items: center;
  height: 50vh;
}

.impact-dropdown-style {
  margin-left: 10px !important;
  margin-right: 20px !important;
}

.drought-check-modal .modal-body {
  font-family: "National";
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000;
}

.alert-heading {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  align-items: baseline;
  background: #28537d;
  color: white;
  padding: 5px 10px;
}

.header-text {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
  align-items: baseline;
  /* color:white; */
}

.heading {
  font-family: "National";
  font-weight: 500;
  font-size: 20px;
}

.close-drought-icon-new {
  color: #faf4f4;
}

.link-text {
  color: #dc9023;
  text-decoration: none;
}

.close-drought-icon {
  color: #888888;
}

.rainfall-modal .modal-header .close > span,
.drought-phase-modal .modal-header .close > span {
}

.rainfall-modal .modal-header .close,
.drought-phase-modal .modal-header .close {
  border: 0;
  margin-top: 5px;
  background-color: white !important;
}

.drought-phase-modal .modal-body {
  padding: 0 !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.no-chart-style {
  text-align: center;
  font-family: "National" !important;
  font-size: 22px !important;
}

.terms-conditions {
  top: 10vh !important;
  position: relative;
  margin-left: 33px !important;
  margin-right: 33px !important;
}

.drought-popup-features:hover {
  cursor: pointer;
  box-shadow: 0 0 20px #333;
}

.drought-chart .apexcharts-toolbar {
  /* top: 570px !important; */
  /* top: 57vh !important; */
  right: 3px;
  /* position: fixed !important; */
}

.drought-chart .apexcharts-title-text {
  position: fixed !important;
  top: 64vh !important;
}

.drought-chart-row .apexcharts-toolbar {
  position: absolute;
  right: auto;
  left: 10px;
  top: 2px !important;
}

.dds-leaflet-draw-css .leaflet-draw-draw-polygon,
.dds-leaflet-draw-css .leaflet-draw-draw-marker {
  display: none !important;
}

.dds-leaflet-draw-css
  .leaflet-draw-section
  .leaflet-draw-toolbar
  .leaflet-draw-edit-edit {
  display: none !important;
}

.dds-leaflet-draw-css
  .leaflet-draw-section
  .leaflet-draw-toolbar
  .leaflet-draw-edit-remove {
  display: none !important;
}

.no-static-record-found {
  margin-top: 40px;
  margin-left: 10vw;
}

@media screen and (min-width: 576px) and (max-width: 786px) {
  .dashboard-card {
    height: 350px;
    flex-direction: column;
  }

  .dt-1 {
    margin-left: 20px;
  }

  .home-page-bulletin-header-h3 {
    font-size: 18px;
  }

  .crop-delete-button {
    padding: 5px;
    font-size: 10px;
  }

  .crop-edit-button {
    padding: 5px;
    font-size: 10px;
  }

  /* .mrl-1{
      margin-left:5.5vw;
    } */
}

@media screen and (min-width: 767px) and (max-width: 992px) {
  .dashboard-card {
    height: 200px;
  }

  .home-page-bulletin-header-h3 {
    font-size: 20px;
  }

  .crop-delete-button {
    /* padding:5px; */
    font-size: 10px;
  }

  .crop-edit-button {
    /* padding:5px; */
    font-size: 10px;
  }

  .dt-1 {
    display: flex;
    margin-left: 10px;
  }

  .mrl-1 {
    margin-left: 1vw;
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .drought-li-checked {
    width: 80%;
  }

  .crop-delete-button {
    /* padding:5px; */
    font-size: 10px;
  }

  .crop-edit-button {
    /* padding:5px; */
    font-size: 10px;
  }

  .dashboard-card {
    height: 250px;
  }

  .dt-1 {
    display: flex;
    margin-left: 10px;
  }

  .mrl-1 {
    margin-left: 1vw;
  }
}

@media screen and (min-width: 1201px) and (max-width: 1400px) {
  .mrl-1 {
    margin-left: 5.5vw;
  }
}

@media screen and (min-width: 1401px) and (max-width: 1600px) {
  .mrl-1 {
    margin-left: 4.7vw;
  }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
  .mrl-1 {
    margin-left: 4.5vw;
  }
}

@media screen and (min-width: 1801px) and (max-width: 2000px) {
  .mrl-1 {
    margin-left: 4vw;
  }
}

@media screen and (min-width: 2001px) and (max-width: 2600px) {
  .mrl-1 {
    margin-left: 3.5vw;
  }
}
