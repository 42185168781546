.ant-layout-sider::-webkit-scrollbar {
    width: 8px;
}

.ant-layout-sider::-webkit-scrollbar-track {
    background: var(--ll-background-color);
}

.ant-layout-sider::-webkit-scrollbar-thumb {
    background-color: var(--ll-color);
    border: 3px solid var(--ll-color);
}