/* === CONTROL === */
.leaflet-control-sm-button {
  background-image: url("images/swipe-mode.svg");
}

.leaflet-control-sm-layer-selector-open {
  /* width: 300px;
  height: 100px; */
  background-color: #fafafa;
}

.leaflet-control-sm-layer-selector-open > a {
  display: none;
}

/* Layer Selector Exit bar */
.leaflet-control-sm-exit-bar {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  height: 20px;
}

.leaflet-control-sm-exit-bar > a {
  text-decoration: none;
  color: black;
  cursor: pointer;
}

/* Layer selector container */
.leaflet-control-sm-layer-selector-container {
  display: flex;
  flex-direction: column;
  gap: 0;
  color: black;
}

.leaflet-control-sm-layer-picker-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 5px;
  justify-content: center;
  flex-wrap: nowrap;
}

.leaflet-control-sm-layer-selector-container > button {
  width: 100%;
}

.leaflet-control-sm-layer-selector-box {
  display: flex;
  flex-direction: column-reverse;
  gap: 3px;
  flex-grow: 1;
}

.leaflet-control-sm-layer-selector-box > select {
  width: 100%;
  height: 25px;
}

/* === RANGE === */
.leaflet-sm-range {
  -webkit-appearance: none;
  display: inline-block !important;
  vertical-align: middle;
  height: 0;
  padding: 0;
  margin: 0;
  border: 0;
  background: rgba(0, 0, 0, 0.25);
  min-width: 100px;
  cursor: pointer;
  pointer-events: none;
  z-index: 999;
}

.leaflet-sm-range::-ms-fill-upper {
  background: transparent;
}

.leaflet-sm-range::-ms-fill-lower {
  background: rgba(255, 255, 255, 0.25);
}

/* Browser thingies */

.leaflet-sm-range::-moz-range-track {
  opacity: 0;
}

.leaflet-sm-range::-ms-track {
  opacity: 0;
}

.leaflet-sm-range::-ms-tooltip {
  display: none;
}

/* For whatever reason, these need to be defined
 * on their own so dont group them */

.leaflet-sm-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  margin: 0;
  padding: 0;
  background: #fff;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  cursor: ew-resize;
  pointer-events: auto;
  border: 1px solid #ddd;
  background-image: url(images/range-icon.png);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 40px 40px;
}

.leaflet-sm-range::-ms-thumb {
  margin: 0;
  padding: 0;
  background: #fff;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  cursor: ew-resize;
  pointer-events: auto;
  border: 1px solid #ddd;
  background-image: url(images/range-icon.png);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 40px 40px;
}

.leaflet-sm-range::-moz-range-thumb {
  padding: 0;
  right: 0;
  background: #fff;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  cursor: ew-resize;
  pointer-events: auto;
  border: 1px solid #ddd;
  background-image: url(images/range-icon.png);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 40px 40px;
}

.leaflet-sm-range:disabled::-moz-range-thumb {
  cursor: default;
}

.leaflet-sm-range:disabled::-ms-thumb {
  cursor: default;
}

.leaflet-sm-range:disabled::-webkit-slider-thumb {
  cursor: default;
}

.leaflet-sm-range:disabled {
  cursor: default;
}

.leaflet-sm-range:focus {
  outline: none !important;
}

.leaflet-sm-range::-moz-focus-outer {
  border: 0;
}

/* === LAYOUT === */
.leaflet-sm-range {
  position: absolute;
  top: 50%;
  width: 100%;
  z-index: 999;
}

.leaflet-sm-divider {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -2px;
  width: 4px;
  background-color: #fff;
  pointer-events: none;
  z-index: 999;
}
